import axios from 'axios'

class EventService {
  private headers: Object
  private baseUrl: string

  constructor() {
    if (!process.env.HUGIN_URL) {
      throw new Error('Missing env var HUGIN_URL')
    }

    this.baseUrl = `${process.env.HUGIN_URL}/`
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  async hasSubmitAnTypeformEvent(
    customerId: number,
    eventId: string,
    responseId?: string
  ) {
    return await axios
      .post(
        `${this.baseUrl}customers/${customerId}/events/${eventId}/submit`,
        {
          responseId,
        },
        { headers: this.headers }
      )
      .then(() => {
        return true
      })
      .catch((err) => {
        throw err
      })
  }
}

export default new EventService()
