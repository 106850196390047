// index.js
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
import { subscriptions } from './subscriptions'
import { cart } from './cart'
import { auth } from './auth'
import { behavior } from './behavior'

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    subscriptions,
    cart,
    auth,
    behavior,
  },
}

const store = () => new Vuex.Store<RootState>(storeOptions)
export default store
