import { ErrorLogger } from '~/plugins/errorLogger'

class LaPosteService {
  private klaviyoPublicKey = 'PZmQi7'

  private async sendEvent(attributes: any) {
    return await fetch(
      `https://a.klaviyo.com/client/events/?company_id=${this.klaviyoPublicKey}`,
      {
        method: 'POST',
        body: JSON.stringify({
          data: {
            type: 'event',
            attributes,
          },
        }),

        headers: {
          accept: 'application/json',
          revision: '2023-07-15',
          'content-type': 'application/json',
        },
      }
    ).catch((err) => {
      ErrorLogger.error(err)
    })
  }

  async hasSpendOneMinuteOnProductPage(
    email: string,
    productUid: string,
    locale: string
  ) {
    return await this.sendEvent({
      profile: {
        data: {
          type: 'profile',
          attributes: {
            email,
          },
        },
      },
      metric: {
        data: {
          type: 'metric',
          attributes: { name: `user_engagement` },
        },
      },
      properties: {
        engagement_type: 'one_minute_on_product',
        locale,
        uid: productUid,
        origin: 'website',
      },
    })
  }

  async addToCart(email: string, productUid: string, locale: string) {
    return await this.sendEvent({
      profile: {
        data: {
          type: 'profile',
          attributes: {
            email,
          },
        },
      },
      metric: {
        data: {
          type: 'metric',
          attributes: { name: `user_engagement` },
        },
      },
      properties: {
        engagement_type: 'add_to_cart',
        locale,
        uid: productUid,
        origin: 'website',
      },
    })
  }
}

export default new LaPosteService()
