export default `fragment VariantFragment on ProductVariant {
  id
  title
  price {
    amount
    currencyCode
  }
  weight
  available: availableForSale
  sku
  compareAtPrice {
    amount
    currencyCode
  }
  image {
    id
    src: transformedSrc(crop: CENTER, maxWidth: 86, maxHeight: 86, scale: 2)
    altText
  }
  selectedOptions {
    name
    value
  }
  unitPrice {
    amount
    currencyCode
  }
}`
