interface Address {
  address1: string
  address2: string
  city: string
  company: string
  country: string
  firstName: string
  formatted: string[]
  id: string
  lastName: string
  latitude: number
  longitude: number
  name: string
  phone: string
  province: string
  zip: string
}

interface Order {
  id: string
  orderNumber: string
}

export default class Customer {
  acceptsMarketing: boolean
  createdAt: Date
  defaultAddress: Address
  addresses: Address[] = []
  displayName: string
  email: string
  firstName: string
  id: string
  lastIncompleteCheckout: string
  lastName: string
  phone: string
  codeFilleul: string
  codeParrain: string
  montantCagnotte: Number
  numberOfOrders: Number
  orders: { [key: string]: Order } = {}
  birthday: Date | null = null
  gender: string | null = null

  constructor(data: any) {
    this.id = data.id || null
    this.displayName = data.displayName || null
    this.firstName = data.firstName || null
    this.lastName = data.lastName || null
    this.email = data.email || null
    this.phone = data.phone || null
    this.defaultAddress = data.defaultAddress || null
    this.acceptsMarketing = data.acceptsMarketing || false
    this.createdAt = new Date(data.createdAt)
    this.codeFilleul = data.codeFilleul || null
    this.codeParrain = data.codeParrain || null
    this.montantCagnotte = data.montantCagnotte || null
    this.numberOfOrders = parseInt(data.numberOfOrders) || 0
    this.lastIncompleteCheckout = data.lastIncompleteCheckout || null

    if (data.addresses && data.addresses.edges.length > 0) {
      this.addresses = data.addresses.edges.map((a: any) => a.node)
    }

    if (data.metafields && data.metafields.length > 0) {
      if (data.metafields.some((m: any) => m && m.key === 'birthday')) {
        this.birthday = new Date(
          data.metafields.find((m: any) => m && m.key === 'birthday').value
        )
      }

      if (
        data.metafields.some(
          (m: any) => m && m.namespace === 'customer' && m.key === 'gender'
        )
      ) {
        this.gender = data.metafields.find(
          (m: any) => m && m.namespace === 'customer' && m.key === 'gender'
        ).value
      } else if (
        data.metafields.some(
          (m: any) => m && m.namespace === 'gender-api' && m.key === 'gender'
        )
      ) {
        const accuracy =
          data.metafields.find(
            (m: any) =>
              m && m.namespace === 'gender-api' && m.key === 'gender-accuracy'
          ) || 0

        if (accuracy > 0.8) {
          this.gender = data.metafields.find(
            (m: any) => m && m.namespace === 'gender-api' && m.key === 'gender'
          ).value
        }
      }
    }

    if (data.orders && data.orders.edges.length > 0) {
      this.orders = data.orders.edges
    }
  }

  get isBirthday(): boolean {
    const today = new Date()

    if (this.birthday) {
      return (
        today.getDate() === this.birthday.getDate() &&
        today.getMonth() === this.birthday.getMonth()
      )
    }

    return false
  }

  public toJSON() {
    return Object.assign({}, this)
  }
}

export class CustomerAccessToken {
  public accessToken: string
  public expiresAt: number

  constructor(data: any) {
    this.accessToken = data.accessToken || ''
    this.expiresAt = data.expiresAt || 0
  }

  public toJSON() {
    return Object.assign({}, this)
  }
}
