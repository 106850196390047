import Cart from '@/entities/cart/Cart'
import CartItem from '@/entities/cart/CartItem'
import {
  UPSELLING_TYPES,
  UPSELLING_LIMIT,
  IUpsellingGroupLink,
  IUpsellingGroupProducts,
  IUpsellingProduct,
  upsellingGroup,
} from '@/const'

export function cartItemIsAnUpsellingReference(
  groups: upsellingGroup[],
  item: CartItem
): boolean {
  let is = false
  for (const group of groups) {
    if (
      group.type === UPSELLING_TYPES.LINK &&
      (group as IUpsellingGroupLink).origins.includes(item.productId)
    ) {
      is = true
    } else if (
      group.type === UPSELLING_TYPES.PRODUCTS &&
      (group as IUpsellingGroupProducts).products.some(
        (g) => g.id === item.productId
      )
    ) {
      is = true
    }
  }

  return is
}

export function selectCurrentUpsellingGroup(
  cart: Cart,
  groups: upsellingGroup[],
  context: 'h' | 'f'
) {
  let currentGroup: IUpsellingGroupLink | IUpsellingGroupProducts | null = null

  for (const item of cart.items) {
    for (const group of groups) {
      if (
        group.type === UPSELLING_TYPES.LINK &&
        (group as IUpsellingGroupLink).origins.includes(item.productId)
      ) {
        if (!currentGroup || currentGroup.weight > group.weight) {
          currentGroup = group
        }
      } else if (
        group.type === UPSELLING_TYPES.PRODUCTS &&
        (group as IUpsellingGroupProducts).products.some(
          (p: IUpsellingProduct) => p.id === item.productId
        )
      ) {
        if (!currentGroup || currentGroup.weight > group.weight) {
          currentGroup = group
        }
      }
    }
  }
  if (!currentGroup) {
    for (const group of groups) {
      if (
        group.type === UPSELLING_TYPES.PRODUCTS &&
        group.canCompleteList &&
        group.context === context
      ) {
        currentGroup = group
        break
      }
    }
  }

  return currentGroup
}

export function extractUpsellItems(
  cart: Cart,
  groups: upsellingGroup[],
  group: IUpsellingGroupLink | IUpsellingGroupProducts,
  context: 'h' | 'f'
) {
  let pp: any = []

  if (group.type === UPSELLING_TYPES.LINK) {
    pp.push((group as IUpsellingGroupLink).target)
  } else if (group.type === UPSELLING_TYPES.PRODUCTS) {
    const filterProducts = (group as IUpsellingGroupProducts).products.filter(
      (p) => !cart.items.some((i) => i.productId === p.id)
    )
    pp = pp.concat(filterProducts)
  }

  // On retire de la liste les produits qui sont potentiellement déjà dans le panier
  pp = pp.filter(
    (p: IUpsellingProduct) => !cart.items.some((i) => i.productId === p.id)
  )

  // S'il reste des places, on vient regarder dans les autres groupes si on peut ajouter des produits
  if (group.type === UPSELLING_TYPES.PRODUCTS && pp.length < UPSELLING_LIMIT) {
    const otherGroup = groups
      .sort((a, b) => {
        if (a.context === b.context) {
          return a.weight - b.weight
        }
        return a.context === context ? -1 : 1
      })
      .filter(
        (g) => g.type === group?.type && g !== group && g.canCompleteList
      ) as IUpsellingGroupProducts[]

    let i = 0
    while (otherGroup[i] && pp.length < UPSELLING_LIMIT) {
      const otherProducts = otherGroup[i].products.filter(
        (p) => !cart.items.some((i) => i.productId === p.id)
      )

      pp = pp.concat(otherProducts)

      i++
    }
  }

  if (pp.length > UPSELLING_LIMIT) {
    pp = pp.slice(0, UPSELLING_LIMIT)
  }

  return pp
}
