import { CartState } from './types'

import Cart from '@/entities/cart/Cart'
import { upsellingGroup } from '@/const'

export const state: CartState = {
  cart: new Cart({}),
  upsell: {
    groups: process.env.UPSELLING_GROUPS
      ? (process.env.UPSELLING_GROUPS as unknown as upsellingGroup[]).sort(
          (a, b) => {
            return a.weight - b.weight
          }
        )
      : [],
    has: false,
    group: null,
    list: [],
    items: {},
  },
  loading: false,
  loadingUpselling: false,
  error: '',
}
