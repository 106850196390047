import { SHOPIFY_TYPES } from '@/const'

export default class ShopifyBase64 {
  /**
   * shopifyBase64.encode
   * @param {SHOPIFY_TYPES} type Le type de structure de l'id shopify
   * @param {String} id l'id de la structure
   * @returns Retourne gid://shopify/${type}/${id}
   */
  static encode(type: SHOPIFY_TYPES, id: string): string {
    return `gid://shopify/${type}/${id}`
  }

  /**
   * shopifyBase64.decode
   * @param {String} [encodedString] La chaine encodée
   * @returns Retourne gid://shopify/${type}/${id}
   */
  static decode(s: string): string {
    return Buffer.from(s, 'base64').toString('ascii')
  }

  /**
   * Retourne l'id de la chaine de charactère encodée en base64
   * @param {String} [encodedString] La chaine encodée qui vient de shopify doit-être dans un format gid://shopify/${TYPE}/${ID}
   * @returns Retourne l'id décodé de la structure shopify
   */
  static getId(encodedString: string | number): number {
    if (typeof encodedString === 'number') {
      return encodedString
    }

    // const s = ShopifyBase64.decode(encodedString)
    const ss = encodedString.split('/')

    return parseInt(ss[ss.length - 1])
  }
}
