import UserErrorFragment from './UserErrorFragment'
import CheckoutUserErrorFragment from './CheckoutUserErrorFragment'
import CheckoutFragment from './CheckoutFragment'

export default `
${UserErrorFragment}
${CheckoutUserErrorFragment}
${CheckoutFragment}
mutation ($input: CheckoutCreateInput!) {
  checkoutCreate(input: $input) {
    userErrors {
      ...UserErrorFragment
    }
    checkoutUserErrors {
      ...CheckoutUserErrorFragment
    }
    checkout {
      ...CheckoutFragment
    }
  }
}`
