import UserErrorFragment from './UserErrorFragment'
import CheckoutUserErrorFragment from './CheckoutUserErrorFragment'
import CheckoutFragment from './CheckoutFragment'

export default `
${UserErrorFragment}
${CheckoutUserErrorFragment}
${CheckoutFragment}
mutation ($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
  checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
    userErrors {
      ...UserErrorFragment
    }
    checkoutUserErrors {
      ...CheckoutUserErrorFragment
    }
    checkout {
      ...CheckoutFragment
    }
  }
}`
