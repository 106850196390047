import { Context, Plugin } from '@nuxt/types'
import { MetaInfo } from 'vue-meta/types/vue-meta'
import { RouteRecord } from 'vue-router'

import Product, { IVariant } from '@/entities/shopify/Product'
import CartItem from '@/entities/cart/CartItem'
import Cart from '@/entities/cart/Cart'

import { CURRENCY, ORIGIN } from '@/const'
import ShopifyBase64 from '@/utils/shopifyBase64'
import Customer from '@/entities/Customer'

import loadGtm from '@/plugins/gtm'

const DATA_LAYER_NAME = 'dataLayer'

class Tracking {
  ctx: Context
  count = 0
  consent: any = {}
  visitorId = ''

  constructor(ctx: Context) {
    this.ctx = ctx

    if (ctx.nuxtState.layout === 'slice-machine') {
      return
    }

    if (
      (process.env.BUILD_ENV === 'production' &&
        window.location.hostname.endsWith('asphalte.com')) ||
      process.env.LOAD_GTM === 'true'
    ) {
      this.installGTM()
    }

    window._paq = window._paq || []
    window._paq.push(['enableHeartBeatTimer', 5])
    window._paq.push(['setDomains', ['*.asphalte.com', '*.fr.asphalte.com']])
    window._paq.push(['enableCrossDomainLinking'])
    window._paq.push(['requireCookieConsent'])

    if (process.env.MATOMO_ABTEST_ID) {
      if (
        typeof window.Piwik === 'object' &&
        typeof window.Matomo?.AbTesting === 'object'
      ) {
        // if matomo.js was embedded before this code
        this.forceExperiment()
      } else {
        // if matomo.js is loaded after this code
        window.matomoAbTestingAsyncInit = this.forceExperiment
      }
    }

    if (process.client) {
      if (window.Cookiebot) {
        this.updateConsent()
      }

      window.addEventListener('CookiebotOnConsentReady', this.updateConsent)
      window.addEventListener('CookieConsentDeclined', this.updateConsent)
      window.addEventListener('CookieConsentAccepted', this.updateConsent)
    }
  }

  getDataLayer() {
    return (
      window[DATA_LAYER_NAME] === null && (window[DATA_LAYER_NAME] = []),
      Array.isArray(window[DATA_LAYER_NAME]) ? window[DATA_LAYER_NAME] : []
    )
  }

  gtag(args: any) {
    this.getDataLayer().push(args)
  }

  installGTM() {
    if (this.ctx.nuxtState.layout === 'slice-machine') {
      return
    }

    loadGtm()
  }

  forceExperiment() {
    const Experiment = window.Matomo.AbTesting.Experiment

    const myExperiment = new Experiment({
      name: process.env.MATOMO_ABTEST_ID, // you can also use '2' (ID of the experiment) to hide the name
      percentage: 100,
      includedTargets: [
        {
          attribute: 'url',
          inverted: '0',
          type: 'equals_simple',
          value: 'https://www.asphalte.com/h/products/le-jean-ultime',
        },
        {
          attribute: 'url',
          type: 'equals_simple',
          value: 'https://www.asphalte.com/h/products/le-jean-slim',
          inverted: '0',
        },
      ],
      excludedTargets: [],
      variations: [
        {
          name: 'original',
          activate: function () {
            // usually nothing needs to be done here
          },
        },
        {
          name: 'version-b', // you can also use '2' (ID of the variation) to hide the name
          activate: function () {
            // eg $('#btn').attr('style', 'color: ' + this.name + ';');
          },
        },
      ],
      trigger: function () {
        // this is important, otherwise a random variation will be chosen
        return false
      },
    })

    myExperiment.forceVariation(
      process.env.SITE_VERSION === 'version-b' ? 'version-b' : 'original'
    )
    // this will call the activate method for the blue variation.
  }

  clearEcommerce() {
    if (this.ctx.nuxtState.layout === 'slice-machine') {
      return
    }

    this.gtag({ event: 'clear_ecommerce', ecommerce: null })
  }

  updateConsent() {
    if (process.client) {
      if (window.Cookiebot?.consent) {
        this.consent = window.Cookiebot.consent

        if (this.consent.statistics) {
          window._paq.push(['setCookieConsentGiven'])
          window._paq.push(['rememberCookieConsentGiven'])
        } else {
          window._paq.push(['forgetCookieConsentGiven'])
        }
      }
    }
  }

  async getUserData(customer?: Customer) {
    const data: any = {}

    if (
      (customer ||
        (this.ctx.store.state.auth && this.ctx.store.state.auth.customer)) &&
      this.consent.marketing
    ) {
      const user: Customer = customer || this.ctx.store.state.auth.customer
      const decodedId = ShopifyBase64.getId(user.id)
      const encodeUserId = await this.digestMessage(decodedId.toString())
      const encodedEmail = await this.digestMessage(user.email)
      const encodePhone = user.phone
        ? await this.digestMessage(user.phone)
        : undefined

      const userData: any = {}
      userData.customer_id = encodeUserId
      userData.email = encodedEmail
      userData.phone = encodePhone
      userData.first_name = user.firstName || undefined
      userData.last_name = user.lastName || undefined
      userData.country = user.defaultAddress
        ? user.defaultAddress.country
        : undefined
      userData.zip = user.defaultAddress ? user.defaultAddress.zip : undefined
      userData.city = user.defaultAddress ? user.defaultAddress.city : undefined

      data.user_data = userData
      data.leadsUserData = {
        sha256_email_address: encodedEmail,
      }
    }

    return data
  }

  event(name: string, options?: any) {
    if (this.ctx.nuxtState.layout === 'slice-machine') {
      return
    }

    this.gtag({
      event: name,
      ...options,
      version: process.env.SITE_VERSION,
    })

    if (this.count >= 2 && this.count < 3) {
      if (
        typeof batchSDK !== 'undefined' &&
        typeof Notification !== 'undefined'
      ) {
        if (
          Notification.permission !== 'granted' &&
          Notification.permission !== 'denied'
        ) {
          batchSDK((api: any) => {
            api.ui.show('native')
          })
          this.count = 3
        }
      }
    }
  }

  setLocale(locale: string) {
    this.event('locale', {
      event_category: 'i18n',
      locale,
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setUserLanguage(locale.substring(0, 2))
      })
    }
  }

  async digestMessage(message: string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(message)
    const hash = await crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hash)) // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('') // convert bytes to hex string
    return hashHex
  }

  async setUser(customer: Customer) {
    const decodedId = ShopifyBase64.getId(customer.id)
    const userData = await this.getUserData(customer)

    this.gtag({
      event: 'set_user',
      event_category: 'user',
      ...userData,
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setCustomUserID(decodedId)
      })
    }

    if (this.consent.statistics) {
      window._paq.push(['setUserId', decodedId])
    }
  }

  context(context: string) {
    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.editUserData((editor: any) => {
          editor.addTag('context', context)
        })
      })
    }
  }

  page(meta: MetaInfo, route: RouteRecord, referrer: RouteRecord) {
    this.count += 1
    this.event('page_view', {
      page_title: meta.title ? meta.title : document.title,
      page_location: window.location.href,
      page_path: route.path,
      page_referrer: referrer && referrer.path ? referrer.path : undefined,
    })

    window._paq.push([
      'setDocumentTitle',
      meta.title ? meta.title : document.title,
    ])
    window._paq.push(['setCustomUrl', window.location.href])

    if (referrer && referrer.path) {
      window._paq.push(['setReferrerUrl', referrer.path])
    }
    window._paq.push(['setCustomDimension', 1, process.env.SITE_VERSION])
    window._paq.push(['trackPageView'])
    window._paq.push(['trackAllContentImpressions'])
    window._paq.push(['enableLinkTracking'])
  }

  modal(name: string, additionnalOptions: object) {
    this.event('modal_view', {
      ...additionnalOptions,
      event_category: 'navigation',
      modal_title: name,
    })
  }

  generateProductCategories(product: Product) {
    const itemCategories: any = {}

    if (product.productType) {
      itemCategories.item_category = product.productType
    }

    product.tags.forEach((t, index) => {
      itemCategories[`item_category${index + 2}`] = t
    })

    return itemCategories
  }

  async product(name: string, product: Product, variants: IVariant[]) {
    const price = product.price

    this.clearEcommerce()

    const userDatas = await this.getUserData()

    this.event('view_item', {
      ...userDatas,
      event_category: 'ecommerce',
      event_label: 'Produit consulté',
      page_location: window.location.href,
      page_path: window.location.pathname,
      ecommerce: {
        currency: product.currency,
        value: price,
        product_name: name,
        product_id: product.id,
        product_category: product.productType,
        items: variants.map((variant) => ({
          ...this.generateProductCategories(product),
          id: variant.id,
          item_id: variant.id,
          item_name: name,
          item_variant: variant.title,
          item_vendor: product.vendor,
          item_brand: 'Asphalte',
          product_id: product.id,
          currency: product.currency,
          price: variant.price,
        })),
      },
    })
  }

  selectVariant(
    name: string,
    product: Product,
    variant: IVariant,
    currency: CURRENCY
  ) {
    this.count += 0.2
    this.clearEcommerce()
    this.event('select_variant', {
      event_category: 'ecommerce',
      event_label: "Sélection d'une variante",
      ecommerce: {
        currency,
        value: variant.price,
        product_id: product.id,
        product_name: name,
        product_category: product.productType,
        items: [
          {
            ...this.generateProductCategories(product),
            id: variant.id,
            item_id: variant.id,
            item_name: name,
            item_variant: variant.title,
            item_vendor: product.vendor,
            item_brand: 'Asphalte',
            product_id: product.id,
            currency,
            price: variant.price,
            quantity: 1,
          },
        ],
      },
    })

    // window._paq.push([
    //   'trackEvent',
    //   'Fiche produit',
    //   'select_color',
    //   "Sélection d'une couleur",
    //   variant.id,
    // ])

    window._paq.push([
      'setEcommerceView',
      variant.sku, // (Required) productSKU
      `${name}`, // (Optional) productName
      [name, product.productType], // (Optional) categoryName
      variant.price, // (Optional) price
    ])
    window._paq.push(['trackPageView'])
  }

  async addToCart(
    name: string,
    product: Product,
    variant: IVariant,
    currency: CURRENCY,
    cart: Cart
  ) {
    this.clearEcommerce()

    const userDatas = await this.getUserData()

    const options: any = {
      ...userDatas,
      event_category: 'ecommerce',
      event_label: 'Ajout au panier',
      ecommerce: {
        currency,
        value: variant.price,
        cart_total: cart.subTotalPrice,
        product_name: name,

        // Pinterest
        product_id: product.id,
        product_category: product.productType,
        product_tags: [product.productType, ...product.tags],
        items: [
          {
            ...this.generateProductCategories(product),
            id: variant.id,
            item_id: variant.id,
            item_name: name,
            item_variant: variant.title,
            item_vendor: product.vendor,
            item_brand: 'Asphalte',
            currency,
            price: variant.price,
            quantity: 1,
          },
        ],
      },
    }

    if (this.ctx.route.query.upsell) {
      options.ecommerce.from = 'upsell'
    }

    this.count += 1
    this.event('add_to_cart', options)

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.trackEvent('add_to_cart', {
          label: name,
          tags: [product.productType, ...product.tags],
          attributes: {
            id: product.id,
          },
        })
      })
    }

    window._paq.push([
      'addEcommerceItem',
      variant.sku,
      name,
      product.productType,
      variant.price,
      1,
    ])

    window._paq.push(['trackEcommerceCartUpdate', cart.subTotalPrice])
  }

  async addSuitToCart(
    name: string,
    product: Product,
    jacket: IVariant | undefined,
    pant: IVariant | undefined,
    currency: CURRENCY,
    cart: Cart
  ) {
    const items = []
    let price = 0

    if (jacket) {
      items.push({
        ...this.generateProductCategories(product),
        id: jacket.id,
        item_id: jacket.id,
        item_name: name,
        item_brand: product.vendor,
        item_variant: `${jacket.opt.group.label} / ${jacket.opt.color.label} / ${jacket.opt.size.label}`,
        currency,
        price: jacket.price,
        quantity: 1,
      })

      window._paq.push([
        'addEcommerceItem',
        jacket.sku,
        name,
        product.productType,
        jacket.price,
        1,
      ])

      price += jacket.price
    }

    if (pant) {
      items.push({
        ...this.generateProductCategories(product),
        id: pant.id,
        item_id: pant.id,
        item_name: name,
        item_brand: product.vendor,
        item_variant: `${pant.opt.group.label} / ${pant.opt.color.label} / ${pant.opt.size.label}`,
        currency,
        price: pant.price,
        quantity: 1,
      })

      window._paq.push([
        'addEcommerceItem',
        pant.sku,
        name,
        product.productType,
        pant.price,
        1,
      ])

      price += pant.price
    }

    this.count += 1
    this.clearEcommerce()

    const userDatas = await this.getUserData()

    this.event('add_to_cart', {
      ...userDatas,
      event_category: 'ecommerce',
      event_label: 'Ajout au panier',
      ecommerce: {
        currency,
        value: price,
        cart_total: cart.subTotalPrice,
        product_name: name,
        items,
      },
    })

    window._paq.push(['trackEcommerceCartUpdate', cart.subTotalPrice])
  }

  addUpSellToCart(name: string, variant: any, currency: CURRENCY) {
    this.clearEcommerce()
    this.event('add_to_cart', {
      event_category: 'ecommerce',
      event_label: 'Ajout au panier',
      ecommerce: {
        currency,
        value: variant.price,
        items: [
          {
            id: variant.id,
            item_id: variant.id,
            item_variant: variant.title,
            item_name: name,
            currency,
            price: variant.price,
            quantity: 1,
          },
        ],
      },
    })

    window._paq.push([
      'addEcommerceItem',
      variant.sku,
      name,
      variant.product.productType,
      variant.price,
      1,
    ])
  }

  removeFromCart(cartItem: CartItem, currency: CURRENCY, cart: Cart) {
    this.clearEcommerce()
    this.event('remove_from_cart', {
      event_category: 'ecommerce',
      event_label: 'Suppression du panier',
      ecommerce: {
        currency,
        value: cartItem.unitPrice,
        items: [
          {
            id: cartItem.variantId,
            item_id: cartItem.variantId,
            item_name: cartItem.productTitle,
            item_variant: cartItem.variantTitle,
            item_brand: 'Asphalte',
            currency,
            price: cartItem.unitPrice,
            quantity: 1,
          },
        ],
      },
    })

    window._paq.push(['removeEcommerceItem', cartItem.variantSKU])
    window._paq.push(['trackEcommerceCartUpdate', cart.subTotalPrice])
  }

  beginCheckout(cart: Cart) {
    const items: any[] = []

    cart.items.forEach((item) => {
      const itemCategories: any = {}

      item.productTags.forEach((t, index) => {
        itemCategories[`item_category${index + 2}`] = t
      })

      items.push({
        id: ShopifyBase64.getId(item.variantId),
        item_id: ShopifyBase64.getId(item.variantId),
        item_name: item.productTitle,
        item_vendor: item.productVendor,
        item_brand: 'Asphalte',
        item_variant: item.variantTitle,
        item_category: item.productType,
        ...itemCategories,
        currency: cart.currency,
        price: item.unitPrice,
        quantity: item.quantity,

        // Pinterest
        product_id: item.productId,
        product_category: item.productType,
      })
    })

    this.clearEcommerce()
    this.event('begin_checkout', {
      event_category: 'ecommerce',
      event_label: 'Initialisation tunnel de paiement',
      ecommerce: {
        currency: cart.currency,
        value: cart.subTotalPrice,
        num_items: cart.items.length,
        items,
      },
    })

    window._paq.push(['trackEcommerceCartUpdate', cart.subTotalPrice])
  }

  scroll(percent: string) {
    this.event('scroll', {
      event_category: 'navigation',
      event_label: 'Scroll',
      value: percent,
    })

    window._paq.push([
      'trackEvent',
      'Behavior',
      'Profondeur de scroll',
      `${percent}%`,
      percent,
    ])
  }

  colorChange(color: string, product: string) {
    this.count += 0.2
    this.event('color_change', {
      event_category: 'navigation',
      event_label: 'Changement de couleur',
      color,
      product,
    })
  }

  hasSeenExperiment(experiment: string) {
    this.event('experiment_seen', {
      event_category: 'ab_testing',
      event_label: 'A vue un test A/B',
      experiment,
    })
  }

  swipe() {
    this.count += 0.2
    this.event('swipe', {
      event_category: 'navigation',
      event_label: "Swipe sur un slider d'image",
    })
  }

  sliderChange(direction: string) {
    this.count += 0.2
    this.event('slider_nav', {
      event_category: 'navigation',
      event_label: "Navigation sur un slider d'image",
      direction,
    })
  }

  async bisSubscribe(email: string, target: string, origin: ORIGIN) {
    const encodedEmail = await this.digestMessage(email)

    this.event('subscribe', {
      event_category: 'bis',
      event_label: 'Inscription Back In Stock',
      origin,
      target,
      user_data: {
        email: encodedEmail,
      },
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.editUserData((editor: any) => {
          editor.addTag('bis_subscribe', target)
        })
      })
    }

    window._paq.push([
      'trackEvent',
      'Acquisition',
      'Subscribe',
      `Subscribe from ${origin} to ${target}`,
    ])
  }

  bisUnSubscribe(target: string, origin: ORIGIN) {
    this.event('unsubscribe', {
      event_category: 'bis',
      event_label: 'Désinscription Back In Stock',
      origin,
      target,
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.editUserData((editor: any) => {
          editor.removeTag('bis_subscribe', target)
        })
      })
    }

    window._paq.push([
      'trackEvent',
      'Acquisition',
      'Unsubscribe',
      `Unsubscribe from ${origin} to ${target}`,
    ])
  }

  async login(customer: Customer) {
    const encodedEmail = await this.digestMessage(customer.email)

    this.event('login', {
      event_category: 'user',
      event_label: 'Connexion',
      user_data: {
        customer_id: customer.id,
        email: encodedEmail,
      },
      method: 'Shopify',
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setCustomUserID(customer.id)
      })
    }

    window._paq.push(['trackEvent', 'User', 'Login', `User has logged in`])
  }

  password() {
    this.event('set_password', {
      event_category: 'user',
      event_label: 'Mot de passe',
      method: 'Shopify',
    })

    window._paq.push([
      'trackEvent',
      'User',
      'SetPassword',
      `User has set they password`,
    ])
  }

  async signUp(customer: Customer) {
    const encodedEmail = await this.digestMessage(customer.email)

    this.event('sign_up', {
      event_category: 'user',
      event_label: 'Inscription',
      user_data: {
        customer_id: customer.id,
        email: encodedEmail,
      },
      method: 'Shopify',
    })

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setCustomUserID(customer.id)
      })
    }

    window._paq.push(['trackEvent', 'User', 'SignUp', `User has signed up`])
  }

  logout() {
    this.event('logout')

    if (typeof batchSDK !== 'undefined') {
      batchSDK((api: any) => {
        api.setCustomUserID(null)
      })
    }

    // User has just logged out, we reset the User ID
    window._paq.push(['resetUserId'])
    // we also force a new visit to be created for the pageviews after logout
    window._paq.push(['appendToTrackingUrl', 'new_visit=1'])
    window._paq.push(['trackPageView'])
    // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
    window._paq.push(['appendToTrackingUrl', ''])
    window._paq.push(['trackEvent', 'User', 'LogOut', `User has logged out`])
  }

  click(name: string, options?: object) {
    this.count += 0.5
    this.event(name, {
      event_category: 'navigation',
      event_label: 'Click sur un lien',
      ...options,
    })

    window._paq.push([
      'trackEvent',
      'Click',
      name,
      options
        ? `User has clicked on ${name} with options ${JSON.stringify(options)}`
        : `User has clicked on ${name}`,
    ])
  }

  filter(group: string, name: string, options?: object) {
    this.event('filter', {
      event_label: "Mise à jour d'un filtre",
      event_category: 'collection',
      filter_group: group,
      filter_name: name,
      ...options,
    })

    window._paq.push([
      'trackEvent',
      'CollectionFilter',
      `Filter${group}${name}`,
      `User has filtered the collection by ${group} ${name}`,
    ])
  }

  slice(id: string) {
    this.event('slice_seen', {
      id,
    })
  }

  optimizeVariant(v: string) {
    this.event('optimize_variant', { variant: v })
  }

  fitleWidgetEnded() {
    window._paq.push([
      'trackEvent',
      'Fitle',
      'WidgetEnded',
      `User has ended the Fitle widget`,
    ])
  }

  deployIdTime(duration: number) {
    window._paq.push([
      'trackEvent',
      'Performance',
      'getDeployIdTime',
      `How long it took to get the deploy ID: ${duration}ms`,
      duration,
    ])
  }

  localVersionIsOutdated() {
    window._paq.push([
      'trackEvent',
      'Performance',
      'localVersionIsOutdated',
      `Local version is outdated - Force refresh`,
    ])
  }

  getDeployIdTooLong() {
    window._paq.push([
      'trackEvent',
      'Performance',
      'getDeployIdTooLong',
      `getDeployId took too long`,
    ])
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $track: Tracking
  }
}

const TrackingPlugin: Plugin = (context, inject) => {
  inject('track', new Tracking(context))
}

export default TrackingPlugin
