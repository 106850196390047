import { GetterTree } from 'vuex'

import { RootState } from '../types'
import { SubscriptionState } from './types'
import UserSubscriptions from '@/entities/UserSubscriptions'

export const getters: GetterTree<SubscriptionState, RootState> = {
  lang(state: SubscriptionState): string {
    return state.lang
  },
  connectedEmail(state: SubscriptionState): string | null {
    return state.connectedEmail
  },
  showAgendaTooltip(state: SubscriptionState): boolean {
    return state.showAgendaTooltip
  },
  account(state: SubscriptionState): UserSubscriptions {
    return state.userSubcriptions instanceof UserSubscriptions
      ? state.userSubcriptions
      : UserSubscriptions.fromJSON(state.userSubcriptions)
  },
  isLoading(state: SubscriptionState): boolean {
    return state.loading
  },
  onFetch(state: SubscriptionState): boolean {
    return state.onFetch
  },
  errors(state: SubscriptionState): string[] {
    return state.errors
  },
}
