import { SubscriptionState } from './types'
import UserSubscriptions from '@/entities/UserSubscriptions'

export const state: SubscriptionState = {
  lang: 'fr-fr',
  connectedEmail: null,
  showAgendaTooltip: true,
  userSubcriptions: new UserSubscriptions({}),
  loading: false,
  onFetch: false,
  errors: [],
}
