interface IUidsPair {
  origin: string
  alter: string
}

interface IUidsToSwitch {
  products: IUidsPair[]
  collections: IUidsPair[]
}

export class ReplaceUids {
  uidsToReplace: Map<string, string>

  constructor(uidsToSwitch?: IUidsToSwitch) {
    this.uidsToReplace = this.generateUidsToReplace(uidsToSwitch)
  }

  generateUidsToReplace(uidsToSwitch?: IUidsToSwitch): Map<string, string> {
    const abtestUids = new Map<string, string>()

    if (!uidsToSwitch) {
      uidsToSwitch = process.env.ABTEST_UID_SWITCH
        ? (process.env.ABTEST_UID_SWITCH as unknown as IUidsToSwitch)
        : {
            products: [],
            collections: [],
          }
    }

    for (const productUidsPair of uidsToSwitch.products) {
      abtestUids.set(productUidsPair.origin, productUidsPair.alter)
    }
    for (const collectionUidsPair of uidsToSwitch.collections) {
      abtestUids.set(collectionUidsPair.origin, collectionUidsPair.alter)
    }

    return abtestUids
  }

  replaceOriginWithAlter(uid: string) {
    let u = uid

    if (this.uidsToReplace.has(uid)) {
      u = this.uidsToReplace.get(uid) as string
    }

    return u
  }

  replaceAlterWithOrigin(uid: string) {
    let u = uid

    if (Array.from(this.uidsToReplace.values()).includes(uid)) {
      for (const [key, value] of this.uidsToReplace) {
        if (uid === value) {
          u = key
          break
        }
      }
    }

    return u
  }
}

export default new ReplaceUids()
