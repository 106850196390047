import VariantFragment from './VariantFragment'

export default `
${VariantFragment}
fragment VariantWithProductFragment on ProductVariant {
  ...VariantFragment
  product {
    id
    title
    handle
    productType
    tags
    vendor
  }
}`
