import axios from 'axios'
import Customer from '~/entities/Customer'

import ShopifyBase64 from '~/utils/shopifyBase64'

class SponsorshipService {
  private baseUrl: string | undefined
  private headers: Object

  constructor() {
    if (!process.env.ACCOUNT_MANAGER_URL) {
      throw new Error('Missing env var ACCOUNT_MANAGER_URL')
    }
    this.baseUrl = process.env.PARRAINAGE_URL
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }

  getSponsorCode(customer: Customer) {
    const params = new URLSearchParams()
    params.append('id', ShopifyBase64.getId(customer.id).toString())
    params.append('name', `${customer.firstName} ${customer.lastName}`)
    params.append('email', customer.email.toString())
    params.append('state', 'true')

    return axios.post(`${this.baseUrl}/api/customer/create/codes`, params, {
      headers: {
        ...this.headers,
      },
    })
  }
}

export default new SponsorshipService()
