import axios from 'axios'

class OrderService {
  private headers: Object
  private baseUrl: string

  private endpoint = {
    orders: 'orders',
  }

  constructor() {
    if (!process.env.ODIN_URL) {
      throw new Error('Missing env var ODIN_URL')
    }

    this.baseUrl = `${process.env.ODIN_URL}/public/`
    this.headers = {
      Accept: 'application/json',
    }
  }

  async getOrdersByCustomer(shopifyCustomerId: string) {
    return await axios
      .get(
        `${this.baseUrl}${this.endpoint.orders}/?shopify_customer_id=${shopifyCustomerId}&format=baldr`,
        {
          headers: this.headers,
        }
      )
      .then((response) => {
        return response.data
      })
  }

  async getOrderStatus(shopifyOrderId: string) {
    return await axios
      .get(
        `${this.baseUrl}${this.endpoint.orders}/?shopify_order_id=${shopifyOrderId}&format=baldr`,
        {
          headers: this.headers,
        }
      )
      .then((response) => {
        if (response.data.length === 0) {
          return null
        }
        return response.data
      })
  }
}

export default new OrderService()
