<template>
  <div
    id="main"
    class="container error-page d-flex align-center justify-center"
  >
    <div class="text-center pt-2 pb-2">
      <h1 v-if="error.statusCode === 404">{{ $t('error.404.title') }}</h1>
      <h1 v-else>{{ $t('error.default.title') }}</h1>

      <img src="~/assets/img/404.webp" class="mt-3 mb-2" />

      <p class="text-center">{{ $t('error.default.text') }}</p>
      <div class="d-flex justify-center">
        <div>
          <primary-button
            :to="link"
            type="nuxt-link"
            :text="$t('error.default.backToHome')"
            with-arrow
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/buttons/Primary'

export default {
  components: {
    PrimaryButton,
  },
  layout: 'error', // you can set a custom layout for the error page
  props: { error: { type: Object, required: true } },
  computed: {
    link() {
      const params = []

      if (this.$i18n.locale !== process.env.DEFAULT_LOCALE) {
        params.push(this.$i18n.locale)
      }

      const currentUrl = this.$router.history.current.path.split('/')

      if (currentUrl.includes('f')) {
        params.push('f')
      } else {
        params.push('h')
      }

      return '/' + params.join('/')
    },
  },
}
</script>

<style lang="scss">
.error-page {
  min-height: 85vh;
}
</style>
