import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { AuthState } from './types'
import Customer, { CustomerAccessToken } from '@/entities/Customer'

export const getters: GetterTree<AuthState, RootState> = {
  authenticated(state: AuthState): boolean | null {
    return state.authenticated
  },
  customer(state: AuthState): Customer | null {
    return state.customer
  },
  token(state: AuthState): CustomerAccessToken {
    return state.token
  },
  isLoading(state: AuthState): boolean {
    return state.loading
  },
  errors(state: AuthState): Error[] {
    return state.errors
  },
}
