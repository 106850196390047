/* eslint-disable */

function w_bq(e, t) {
  var n = new XMLHttpRequest()
  ;(n.onreadystatechange = function () {
    this.readyState === 4 &&
      (this.status === 200 ? t(this.responseText) : t(!0))
  }),
    n.open('GET', e, !0),
    n.send()
}
function wb_ck(e, t, n) {
  if (
    ((n = Object.assign({}, n)),
    arguments.length > 1 && String(t) !== '[object Object]')
  ) {
    if ((t || (n.expires = -1), typeof n.expires === 'number')) {
      var r = n.expires,
        i = (n.expires = new Date())
      i.setDate(i.getDate() + r)
    }
    t = String(t)
    var o = [
      encodeURIComponent(e),
      '=',
      n.raw ? t : encodeURIComponent(t),
      n.expires ? '; expires=' + n.expires.toUTCString() : '',
      n.path ? '; path=' + n.path : '; path=/',
      n.domain ? '; domain=' + n.domain : '',
      '; secure=true; sameSite=None',
    ]
    return (document.cookie = o.join(''))
  }
  var a = (n = t || {}).raw
      ? function (e) {
          return e
        }
      : decodeURIComponent,
    c = new RegExp('(?:^|; )' + encodeURIComponent(e) + '=([^;]*)').exec(
      document.cookie
    )
  return c && c[1] && c[1] !== 'null' ? a(c[1]) : null
}
function wb_uq(r, e) {
  var i
  return (
    window.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (e, t, n) {
        t == r && (i = n)
      }
    ),
    i
  )
}
function wb_utm() {
  if (window.location.search) {
    var n = {}
    ;['utm_source', 'utm_medium', 'utm_campaign', 'utm_term'].forEach(function (
      e
    ) {
      var t = wb_uq(e)
      t && (n['apihelper_' + e] = t)
    }),
      Object.keys(n).length > 0 &&
        wb_ck('wb_utm', JSON.stringify(n), { expires: 365 })
  }
}
wb_utm(),
  (window.wb_apihelper = function (t, n) {
    if (!t.vendor) return alert('wb_apihelper() `options.vendor` not defined')
    if (!t.action) return alert('wb_apihelper() `options.action` not defined')
    var e = t.action,
      r = t.vendor,
      i = wb_ck('wb_utm')
    if ((i && (t = Object.assign(t, JSON.parse(i))), t.code)) {
      var o = wb_ck('wb_price_rule')
      if (o) {
        var a = o.split(',')[0],
          c = o.split(',')[1]
        if (+t.price_rule_id == +a)
          return n(!1, {
            statusCode: 200,
            message:
              'Discount code already created: ' +
              c +
              ' for price rule id: ' +
              a,
            data: { code: c, price_rule_id: a },
          })
      }
    }
    delete t.action,
      delete t.vendor,
      w_bq(
        'https://apps.belkapp.com/shopify/apihelper/' +
          r +
          '/' +
          e +
          '?d=asphalte.com&' +
          new URLSearchParams(Object.entries(t)).toString(),
        function (e) {
          return e
            ? (e = JSON.parse(e)).statusCode !== 200
              ? (console.log(e), n(!0, e))
              : (e.data &&
                  e.data.code &&
                  wb_ck(
                    'wb_price_rule',
                    e.data.price_rule_id + ',' + e.data.code,
                    { expires: 365 }
                  ),
                n(!1, e),
                void (
                  (r === 'klaviyo-subscribe')
                  //   ((_learnq = _learnq || []),
                  //     _learnq.push(['identify', { $email: t.email }]))
                ))
            : n(!0, 'Unknown error. Contact admin@belkapp.com')
        }
      )
  })

/* eslint-enable */
