import Attribute from './Attribute'
import ShopifyBase64 from '@/utils/shopifyBase64'

interface IOption {
  name: string
  value: string
}
export default class CartItem {
  lineId: string
  variantId: string
  quantity: number
  customAttributes: Attribute[]
  imageUrl: string
  productId: number
  productTitle: string
  productType: string
  productTags: string[]
  productVendor: string[]
  variantTitle: string
  variantSKU: string
  productHandle: string
  unitPrice: number
  selectedOptions: IOption[] = []

  constructor(data: any) {
    this.lineId = data.id || ''
    this.productId = data.variant.product
      ? ShopifyBase64.getId(data.variant.product.id)
      : 0
    this.productTitle = data.variant.product ? data.variant.product.title : ''
    this.quantity = data.quantity || 0

    this.variantId = data.variant.id || ''
    this.variantTitle = data.variant.title || ''
    this.variantSKU = data.variant.sku || ''
    this.unitPrice = data.variant.price
      ? parseInt(data.variant.price.amount)
      : 0
    this.imageUrl = data.variant.image ? data.variant.image.src : ''
    this.productHandle = data.variant.product ? data.variant.product.handle : ''
    this.productType = data.variant.product
      ? data.variant.product.productType
      : ''
    this.productTags = data.variant.product ? data.variant.product.tags : ''
    this.productVendor = data.variant.product ? data.variant.product.vendor : ''

    if (
      data.variant.selectedOptions &&
      data.variant.selectedOptions.length > 0
    ) {
      this.selectedOptions = data.variant.selectedOptions
    }

    this.customAttributes = []
    if (data.customAttributes && data.customAttributes.length > 0) {
      for (const attribute of data.customAttributes) {
        this.customAttributes.push(new Attribute(attribute))
      }
    }
  }

  get totalPrice(): number {
    return this.quantity * this.unitPrice
  }

  public toCheckoutLineItemInput(): Object {
    return {
      variantId: this.variantId,
      quantity: this.quantity,
      customAttributes: this.customAttributes,
    }
  }

  public toString(): Object {
    return {
      id: this.variantId,
      quantity: this.quantity,
      customAttributes: this.customAttributes,
    }
  }

  public toJSON() {
    return Object.assign({}, this)
  }
}

export interface CartItemInterface {
  variantId: string
  quantity: number
  customAttributes: Object[]
}
