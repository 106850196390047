export const PRODUCTS_GRAPHQL = `
id
handle
totalInventory
productType
priceRange {
    minVariantPrice {
        amount
        currencyCode
    }
    maxVariantPrice {
        amount
        currencyCode
    }
}
compareAtPriceRange {
    minVariantPrice {
        amount
        currencyCode
    }
    maxVariantPrice {
        amount
        currencyCode
    }
}
tags
metafields(identifiers: [{namespace: "prismic", key: "links"}, {namespace: "stock", key: "start_date_web"}, {namespace: "stock", key: "end_date"}]) {
    namespace
    key
    value
}
variants(first: 100) {
    edges {
        node {
            id
            quantityAvailable
            sku
            barcode
            price {
                amount
                currencyCode
            }
            compareAtPrice {
                amount
                currencyCode
            }
            availableForSale
            selectedOptions {
                name
                value
            }
        }
    }
}
`
