import { LS_NETLIFY_DEPLOY_ID } from '@/const'

const promiseWithTimeLimit = (promise, time, exception) => {
  let timer
  return Promise.race([
    promise,

    // SI le fetch prend trop de temps, on rejette la promesse au bout de `time` ms et on renvoie une erreur `exception` reject(exception)
    // eslint-disable-next-line promise/param-names
    new Promise((_, reject) => (timer = setTimeout(reject, time, exception))),
  ]).finally(() => clearTimeout(timer))
}

const wait = (delay) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, delay)
  })
}

export default async function ({ route, $track, $sentry }) {
  if (
    process.client &&
    process.env.NODE_ENV === 'production' &&
    process.env.BUILD_ENV === 'production'
  ) {
    const timeoutError = Symbol('timeoutError')
    const performanceObserver = new PerformanceObserver((list) => {
      const l = list.getEntries().filter((e) => {
        return e.name.includes('getDeployId')
      })

      if (l.length === 0) {
        return
      }

      const last = l[l.length - 1]

      if (last.initiatorType === 'fetch') {
        $track.deployIdTime(last.duration)
      }
    })

    try {
      const localBuildID = localStorage.getItem(LS_NETLIFY_DEPLOY_ID)

      if (localBuildID === null) {
        return
      }

      performanceObserver.observe({ type: 'resource' })

      promiseWithTimeLimit(
        await fetch(`/getDeployId`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then(async (res) => {
            const remoteBuildID = res?.deployId

            if (remoteBuildID !== undefined && localBuildID !== remoteBuildID) {
              $track.localVersionIsOutdated()
              localStorage.removeItem(LS_NETLIFY_DEPLOY_ID)
              window.location.assign(route.fullPath)
              await wait(3000)
            }
          }),
        500,
        timeoutError
      ).finally(() => {
        setTimeout(() => {
          performanceObserver.disconnect()
        }, 1000)
      })
    } catch (e) {
      if (e === timeoutError) {
        // $sentry.captureMessage('Warning: getDeployId has taken too long')
        // getDeployId a pris trop de temps
        $track.getDeployIdTooLong()
        localStorage.removeItem(LS_NETLIFY_DEPLOY_ID)
        window.location.assign(route.fullPath)
        await wait(3000)
      } else {
        $sentry.captureException(e)
      }

      performanceObserver.disconnect()
    }
  }
}
