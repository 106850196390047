import { PaginationCriteria } from '~/entities/api/Pagination'

export interface TestimonialInterface {
  id: string
  note: number
  comment?: string
  name: string
  color?: string
  size?: string
  submitDate: string
  retour: string
}

export interface TestimonialPaginationCriteria extends PaginationCriteria {
  [id: string]: string | number[] | undefined
  color?: string
  size?: string
  note?: string
}

export default class Testimonial {
  id: string
  note: number
  comment: string | null
  author: string
  color: string | null
  size: string | null
  retour: string | null
  submitDate: Date

  constructor(data: TestimonialInterface) {
    this.id = data.id
    this.note = data.note
    this.comment = data.comment || null
    this.author = data.name
    this.color = data.color || null
    this.size = data.size || null
    this.retour = data.retour || null
    this.submitDate = new Date(data.submitDate)
  }
}
