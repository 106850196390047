import MailingAddressFragment from './MailingAddressFragment'
import VariantWithProductFragment from './VariantWithProductFragment'

export default `
${MailingAddressFragment}
${VariantWithProductFragment}
fragment CheckoutFragment on Checkout {
  id
  ready
  requiresShipping
  note
  paymentDue {
    amount
    currencyCode
  }
  webUrl
  orderStatusUrl
  taxExempt
  taxesIncluded
  currencyCode
  totalTax {
    amount
    currencyCode
  }
  lineItemsSubtotalPrice {
    amount
    currencyCode
  }
  subtotalPrice {
    amount
    currencyCode
  }
  totalPrice {
    amount
    currencyCode
  }
  completedAt
  createdAt
  updatedAt
  email
  shippingLine {
    handle
    price {
      amount
      currencyCode
    }
    title
  }
  customAttributes {
    key
    value
  }
  order {
    id
    processedAt
    orderNumber
    subtotalPrice {
      amount
      currencyCode
    }
    totalShippingPrice {
      amount
      currencyCode
    }
    totalTax {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    currencyCode
    totalRefunded {
      amount
      currencyCode
    }
    customerUrl
    shippingAddress {
      ...MailingAddressFragment
    }
    lineItems (first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          title
          variant {
            ...VariantWithProductFragment
          }
          quantity
          customAttributes {
            key
            value
          }
        }
      }
    }
  }
  lineItems (first: 250) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        title
        variant {
          ...VariantWithProductFragment
        }
        quantity
        customAttributes {
          key
          value
        }
      }
    }
  }
}`
