import axios, { AxiosPromise } from 'axios'

// https://www.ipify.org/ => API publique pour récupérer l'IP publique sans rate limit

interface IPInterface {
  ip: string
}

class IpAddressService {
  private baseUrl: string

  constructor() {
    // console.log('var env : ', process.env.IP_ADDRESS_URL)
    // if (!process.env.IP_ADDRESS_URL) {
    //   throw new Error('process.env.IP_ADDRESS_URL is mandatory')
    // }
    this.baseUrl = process.env.IP_ADDRESS_URL
      ? process.env.IP_ADDRESS_URL
      : 'https://api.ipify.org?format=json'
  }

  getIpAddress(): AxiosPromise<IPInterface> {
    // console.log(this.baseUrl)
    return axios.get(this.baseUrl)
  }
}

export default new IpAddressService()
