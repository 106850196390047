import { GetterTree } from 'vuex'

import { RootState } from '../types'
import { CartState } from './types'

import Cart from '@/entities/cart/Cart'

export const getters: GetterTree<CartState, RootState> = {
  cart(state: CartState) {
    return state.cart instanceof Cart ? state.cart : Cart.fromJSON(state.cart)
  },
  upsell(state: CartState) {
    return state.upsell
  },
  isLoading(state: CartState): boolean {
    return state.loading
  },
  isLoadingUpselling(state: CartState): boolean {
    return state.loadingUpselling
  },
  error(state: CartState): string {
    return state.error
  },
}
