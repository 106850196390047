import productService from './productService'
import notificationCenterService from './notificationCenterService'
import cartService from './cartService'
import checkoutService from './checkoutService'
import accountManagerService from './accountManagerService'
import customerService from './customerService'
import linkResolverService from './linkResolverService'
import testimonialService from './testimonialService'
import pageService from './pageService'
import sponsorshipService from './sponsorshipService'
import ipAddressService from './ipAddressService'
import multipassLoginService from './multipassLoginService'
import collectionService from './collectionService'
import prismicProductService from './prismicProductService'
import orderService from './orderService'
import laPosteService from './laPosteService'
import eventService from './eventService'

export default {
  productService,
  notificationCenterService,
  cartService,
  checkoutService,
  linkResolverService,
  customerService,
  accountManagerService,
  testimonialService,
  pageService,
  sponsorshipService,
  ipAddressService,
  multipassLoginService,
  collectionService,
  prismicProductService,
  orderService,
  laPosteService,
  eventService,
}
