import { STATUS } from './../const/index'
interface IHeadData {
  title: string
  route: string
  type: string
  product?: any
  prismic: any
  defaultTitle: string
  defaultDesc: string
}

interface IMetaLink {
  rel: string
  hreflang?: string
  href: string
}

class PageService {
  getHead(data: IHeadData, alternateLinks: IMetaLink[] = []) {
    let desc = data.defaultDesc

    if (data.prismic) {
      if (data.prismic.meta && data.prismic.meta.desc) {
        desc = data.prismic.meta.desc
      } else if (data.prismic.data && data.prismic.data.meta_description) {
        desc = data.prismic.data.meta_description
      }
    }

    const head: any = {
      title: data.title
        ? data.title.includes('Asphalte')
          ? data.title
          : `${data.title} - Asphalte`
        : data.defaultTitle,
      link: alternateLinks.concat([
        {
          rel: 'canonical',
          href: data.route,
        },
      ]),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: desc,
        },
        { hid: 'og:type', property: 'og:type', content: data.type },
        {
          hid: 'og:title',
          property: 'og:title',
          content: data.title || data.defaultTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: desc,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: data.route,
        },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: data.title || data.defaultTitle,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: desc,
        },
      ],
    }

    const images = []
    if (
      data.prismic &&
      data.prismic.data &&
      data.prismic.data.meta_images &&
      data.prismic.data.meta_images.length > 0
    ) {
      for (const img of data.prismic.data.meta_images) {
        if (img.image && img.image.url) {
          images.push(img.image.url)
        }
      }
    }

    if (
      images.length === 0 &&
      data.type === 'product' &&
      data.prismic &&
      data.prismic.image &&
      data.prismic.image.url
    ) {
      images.push(data.prismic.image.url)
    } else if (
      images.length === 0 &&
      data.type === 'article' &&
      data.prismic &&
      data.prismic.data.image &&
      data.prismic.data.image.url
    ) {
      images.push(data.prismic.data.image.url)
    }

    if (images.length > 0) {
      for (const img of images) {
        head.meta.push({
          hid: 'og:image',
          property: 'og:image',
          content: img,
        })
      }

      head.meta.push({
        hid: 'twitter:image',
        name: 'twitter:image',
        content: images[0],
      })
    }

    if (data.type === 'product' && data.product) {
      head.meta.push({
        hid: 'og:price',
        property: 'og:price',
        content: data.product.price,
      })

      head.meta.push({
        hid: 'og:currency',
        property: 'og:currency',
        content: data.product.currency,
      })

      if (!head.script) {
        head.script = []
      }

      const schema: any = {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: data.prismic.name,
        description: desc,
        image: images,
        sku: data.product.id.toString(),
        productID: data.product.id.toString(),
        category: data.product.productType,
        brand: {
          '@type': 'Brand',
          name: 'Asphalte',
          logo: `${
            process.env.BASE_URL
              ? process.env.BASE_URL
              : 'http://localhost:3000'
          }/asphalte-monogramme.svg`,
        },
        offers: {
          '@type': 'Offer',
          url: data.route,
          price: data.product.price.toString(),
          priceCurrency: 'EUR',
          itemCondition: 'https://schema.org/NewCondition',
        },
      }

      if (data.prismic.averageRating) {
        schema.aggregateRating = {
          '@type': 'AggregateRating',
          ratingValue: data.prismic.averageRating.toString(),
          reviewCount: data.prismic.numberOfTestimonials.toString(),
          bestRating: '5',
          worstRating: '1',
        }
      }

      if (
        (data.product.available &&
          data.prismic.status === STATUS.PRECOMMANDE) ||
        data.prismic.status === STATUS.STOCK
      ) {
        if (data.prismic.status === STATUS.PRECOMMANDE) {
          schema.offers.availability = 'https://schema.org/PreOrder'
        } else {
          schema.offers.availability = 'https://schema.org/InStock'
        }
      } else {
        schema.offers.availability = 'https://schema.org/OutOfStock'
      }

      // SCHEMA PRODUCT
      head.script.push({
        type: 'application/ld+json',
        json: schema,
      })

      // SCHEMA BREADCRUMB
      // head.script.push({
      //   type: 'application/ld+json',
      //   json: {
      //     '@context': 'https://schema.org',
      //     '@type': 'BreadcrumbList',
      //     itemListElement: [
      //       {
      //         '@type': 'ListItem',
      //         position: 1,
      //         name: ,
      //         item: 'https://example.com/books',
      //       },
      //       {
      //         '@type': 'ListItem',
      //         position: 2,
      //         name: data.title,
      //         item: data.route,
      //       },
      //     ],
      //   },
      // })
    }

    if (data.type === 'home') {
      if (!head.script) {
        head.script = []
      }

      head.script.push({
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Corporation',
          name: 'Asphalte',
          legalName: 'Six & Sept',
          url: 'https://www.asphalte.com',
          logo: 'https://www.asphalte.com/asphalte-logo.png',
          brand: {
            '@type': 'Brand',
            name: 'Asphalte',
            logo: `${process.env.BASE_URL}/asphalte-monogramme.svg`,
          },
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '05 64 21 12 77',
            contactType: 'customer service',
            areaServed: ['GB', 'FR', 'DE'],
            availableLanguage: ['en', 'fr', 'German'],
          },
          sameAs: [
            'https://www.facebook.com/AsphalteParis/',
            'https://www.linkedin.com/company/asphalte-paris/',
            'https://www.instagram.com/asphalte_femme',
            'https://www.instagram.com/asphalte_homme',
            'https://www.youtube.com/channel/UCUhddkQay4kIuYTDsniMosg',
          ],
        },
      })
    }

    if (data.type === 'article') {
      const image: string[] = []

      if (!head.script) {
        head.script = []
      }

      if (data.prismic.data.image && data.prismic.data.image.url) {
        image.push(data.prismic.data.image.url)
      }

      if (data.prismic.data.header && data.prismic.data.header.url) {
        image.push(data.prismic.data.header.url)
      }

      head.script.push({
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'NewsArticle',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': 'https://google.com/article',
          },
          headline: data.title,
          image,
          datePublished: data.prismic.first_publication_date,
          dateModified: data.prismic.last_publication_date,
          author: {
            '@type': 'Organization',
            name: 'Asphalte',
            logo: {
              '@type': 'ImageObject',
              url: `${process.env.BASE_URL}/asphalte-monogramme.svg`,
            },
          },
          publisher: {
            '@type': 'Organization',
            name: 'Asphalte',
            logo: {
              '@type': 'ImageObject',
              url: `${process.env.BASE_URL}/asphalte-monogramme.svg`,
            },
          },
        },
      })
    }

    return head
  }
}

export default new PageService()
