<template>
  <div class="loader">
    <svg viewBox="0 0 80 80">
      <circle cx="40" cy="40" r="35"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconLoading',
}
</script>

<style lang="scss">
.loader {
  --path: currentcolor;
  --dot: #2d7ad3;
  --duration: 3s;
  --loader-width: 1.5rem; // 24px
  --dot-width: calc(16 * var(--loader-width) / 80);

  width: var(--loader-width);
  height: var(--loader-width);
  position: relative;

  &::before {
    content: '';
    width: var(--dot-width);
    height: var(--dot-width);
    border-radius: 50%;
    position: absolute;
    display: block;
    background: var(--dot);
    top: calc(50% - var(--dot-width) / 2);
    left: 0;
    transform: translate(0, 0);
    animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
      infinite;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;

    circle {
      fill: none;
      stroke: var(--path);
      stroke-width: 10px;
      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-dasharray: 160 50 160 50;
      stroke-dashoffset: 75;
      animation: pathCircle var(--duration)
        cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
  }
}

@keyframes dotRect {
  25% {
    transform: translate(
      calc(var(--loader-width) / 2 - var(--dot-width) / 2),
      calc(var(--loader-width) / 2 - var(--dot-width) / 2)
    );
  }

  50% {
    transform: translate(calc(var(--loader-width) - var(--dot-width)), 0);
  }

  75% {
    transform: translate(
      calc(var(--loader-width) / 2 - var(--dot-width) / 2),
      calc(var(--loader-width) / -2 + var(--dot-width) / 2)
    );
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 130;
  }

  50% {
    stroke-dashoffset: 180;
  }

  75% {
    stroke-dashoffset: 230;
  }

  100% {
    stroke-dashoffset: 285;
  }
}
</style>
