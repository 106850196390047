import { MutationTree } from 'vuex'
import { SubscriptionState } from './types'
import UserSubscriptions from '@/entities/UserSubscriptions'

export const mutations: MutationTree<SubscriptionState> = {
  LANG(state: SubscriptionState, lang: string) {
    state.lang = lang
  },
  CONNECTED_EMAIL(state: SubscriptionState, connectedEmail: string) {
    state.connectedEmail = connectedEmail
  },
  USER_SUBSCRIPTIONS(
    state: SubscriptionState,
    userSubcriptions: UserSubscriptions
  ) {
    state.userSubcriptions = userSubcriptions
  },
  SHOW_AGENDA_TOOLTIP(state: SubscriptionState, should: boolean) {
    state.showAgendaTooltip = should
  },
  LOADING(state: SubscriptionState, loading: boolean) {
    state.loading = loading
  },
  ON_FETCH(state: SubscriptionState, onFetch: boolean) {
    state.onFetch = onFetch
  },
  ERRORS(state: SubscriptionState, errors: Error[]) {
    state.errors = errors.map((e) => e.message)
  },
  CLEAR_ERRORS(state: SubscriptionState) {
    state.errors = []
  },
}
